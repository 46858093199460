import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';



const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;


export const StyledButton = styled.button`
  padding: 10px;
  display: inline-block;
  position: relative;
  test-align: center;
  font-size: 30px;
  padding: 20px;
  font-family: 'Press Start 2P';
`;

export const StyledRoundButton = styled.button`
font-family: 'Press Start 2P';
  padding: 10px;
  border: none;
  font-family: Press Start 2P;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
font-family: 'Press Start 2P';
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
font-family: 'Press Start 2P';
  width: 300px;
  @media (min-width: 767px) {
    width: 450px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
font-family: 'Press Start 2P';
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
padding: 10px;
  display: inline-block;
  position: relative;
  test-align: center;
  font-size: 20px;
  padding: 20px;
  font-family: 'Press Start 2P';
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`  
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    
    <s.Screen>
      <s.Container
    flex={1}
    ai={"center"}
    style={{ padding: 24, backgroundColor: "var(--primary)" }}
    image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
  >
        
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg  alt={"Stephen Sanchez"} src={"/config/images/mintplacer.gif"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
             // border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 15,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
             <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Excluding gas fees.
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"Stephen Sanchez"}
              src={"/config/images/mintplacer.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
           
          </s.Container>
         
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ 
          width: "45%",
          fontFace: 'Press Start 2P',
       }}>
        <Accordion>

<AccordionSummary  expandIcon={<ExpandMore />}>
How do I mint from Metamask mobile wallet? 
</AccordionSummary>

<AccordionDetails>
In order to mint from your Metamask Wallet from mobile. You will need to copy the URL and paste it into you metamask wallet browser. 
</AccordionDetails>

</Accordion>
      
        <Accordion>

<AccordionSummary  expandIcon={<ExpandMore />}>
What is an NFT? 
</AccordionSummary>

<AccordionDetails>
Non-fungible tokens (NFTs) are unique tokens that are linked to digital (and sometimes physical) content, providing proof of ownership. They have many use cases, including artwork, digital collectibles, music, and items in video games.
</AccordionDetails>

</Accordion>

<Accordion>

<AccordionSummary  expandIcon={<ExpandMore />}>
What blockchain are these NFTs minted on?
</AccordionSummary>

<AccordionDetails>
NFTs are minted on ERC-721 compliant contracts deployed to the Ethereum blockchain. This is the "gold standard" and most widely-supported format for digital collectibles.
</AccordionDetails>

</Accordion>

<Accordion>

<AccordionSummary  expandIcon={<ExpandMore />}>
What does it mean to ‘mint’ an NFT? 
</AccordionSummary>

<AccordionDetails>
<p style= {{ fontFamily: 'Press Start 2P' }} >When you mint an NFT, you begin its existence on the blockchain and become its first owner. Similar to how coins are minted and added into circulation, minting an NFT certifies the token as unique and adds it to the blockchain for the first time.</p>
</AccordionDetails>

</Accordion>
<Accordion>

<AccordionSummary  expandIcon={<ExpandMore />}>
What do I receive after purchasing an NFT from the Stephen Sanchez Collection? 
</AccordionSummary>

<AccordionDetails>
When you mint an NFT, a digital collectible is deposited into your Ethereum wallet. These digital collectibles consist of an NFT artwork by Stephen Sanchez. NFT holders will also have early access to upcoming Stephen Sanchez collections and drops. NFT holders must keep the original NFT in their Ethereum wallet for early access and airdrops.       
</AccordionDetails>

</Accordion>
<Accordion>

<AccordionSummary  expandIcon={<ExpandMore />}>
First-Time Buyer?
</AccordionSummary>

<AccordionDetails>
Setup your own cryptocurrency wallet via Metamask.

Once you have your wallet set up, you’ll need to buy some ETH. You can buy this directly via the metamask plugin/extension you’ve installed in your chrome browser or via an exchange (we recommend coinbase). s
If you purchased Ethereum via an exchange (ie: coinbase), you will want to send the amount needed to your metamask address to prepare to mint your NFT. 
After you have the ETH in your metamask wallet, you will be able to connect to the website via the “connect wallet” button in the top right-hand corner.
Make sure to account for a small amount of a ‘gas or transaction fee’ on top of the needed Ethereum.
If you have any questions, please feel free to join the frensHouse Discord channel for further questions.
</AccordionDetails>

</Accordion>
<Accordion>

<AccordionSummary  expandIcon={<ExpandMore />}>
Will the NFTs be sold on open marketplaces? 
</AccordionSummary>

<AccordionDetails>
Primary sales of the Stephen Sanchez 8bit collection NFTs will be on sanchez.frens.house Stephen Sanchez 8bit collection tokens will appear on secondary marketplaces like OpenSea and Rarible etc. for resale purchase. Stephen Sanchez 8bit collection NFTs will adhere to the ERC-721 standard.         </AccordionDetails>

</Accordion>

<Accordion>

<AccordionSummary  expandIcon={<ExpandMore />}>
Can I resell my NFT? 
</AccordionSummary>

<AccordionDetails>
Non-fungible tokens (NFTs) are unique tokens that are linked to digital (and sometimes physical) content, providing proof of ownership. They have many use cases, including artwork, digital collectibles, music, and items in video games.
</AccordionDetails>

</Accordion>

<Accordion>

<AccordionSummary  expandIcon={<ExpandMore />}>
What blockchain are these NFTs minted on?
</AccordionSummary>

<AccordionDetails>
Yes. Stephen Sanchez 8bit tokens can be resold on NFT marketplaces such as OpenSea and Rarible etc. If a Stephen Sanchez token is resold there will be a 10% royalty fee to frenshouse and then distributed to the artist.         </AccordionDetails>

</Accordion>
<Accordion>

<AccordionSummary  expandIcon={<ExpandMore />}>
How do I view my NFT?  
</AccordionSummary>

<AccordionDetails>
Please go to opensea.io. here you can connect your wallet to view your freshly minted NFT in your opensea profile. 
</AccordionDetails>

</Accordion>

<Accordion>

<AccordionSummary  expandIcon={<ExpandMore />}>
Ok, I still have questions. Who do I contact?
</AccordionSummary>

<AccordionDetails>
Please reach out to us at info@frens.House or contact the frensHouse Discord with any further questions you may have.
</AccordionDetails>

</Accordion>

         
        </s.Container>

      </s.Container>
      

    </s.Screen>
    
  );
}

export default App;
